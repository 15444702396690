import List, { listItem } from '@tilda/common/List'
import random from 'lodash.random'
import names from '@tilda/assets/data.json'
import Render from '@tilda/common/Popups/render'

function getNumberDecliner ({ n, single, multiple, multiple2 }: {
  n: number;
  single: string;
  multiple: string;
  multiple2: string;
}) {
  const absNum = Math.abs(n)
  const cases = [2, 0, 1, 1, 1, 2]
  const optionsArray = [single || multiple, multiple2 || multiple, multiple]
  return optionsArray[(absNum % 1 !== 0) ? 1 : (absNum % 100 > 4 && absNum % 100 < 20) ? 2 : cases[(absNum % 10 < 5) ? absNum % 10 : 5]]
}

export type typeBuy = {
  enable: boolean,
  delay: number,
  interval: number,
  template: string
}

export type typeOnline = {
  enable: boolean,
  max: number,
  min: number,
  position: number,
  template: string
}

export type Lang = 'ru' | 'en'
export type LangItem = {
  lang: Lang;
  percent: number;
  repeat: boolean;
}

export default class Popups {
  private readonly position: string = 'right bottom'
  private readonly langs: LangItem[] = [
    {
      lang: 'ru',
      percent: 70,
      repeat: true
    },
    {
      lang: 'en',
      percent: 30,
      repeat: false
    }
  ]

  private typeBuy: typeBuy = {
    enable: true,
    delay: 0,
    interval: 107,
    template: '<b>%name%</b> из г. %city% только что оплатила курс "Медитация. Начало"'
  }

  private typeOnline: typeOnline = {
    enable: true,
    max: 8,
    min: 3,
    position: 2000,
    template: 'Сейчас еще <b>%count% %people%</b> просматривают эту страницу'
  }

  private startTimer: number | null = null
  private intervalTimer: number | null = null
  // eslint-disable-next-line @typescript-eslint/ban-types
  private onScroll: Function | null = null

  constructor ({ typeBuy, typeOnline, position, langs }: { typeBuy?: typeBuy, typeOnline?: typeOnline, position?: string, langs?: LangItem[] | string | Lang }) {
    if (typeBuy) {
      this.typeBuy = typeBuy
    }

    if (typeOnline) {
      this.typeOnline = typeOnline
    }

    if (position) {
      this.position = position
    }

    if (langs) {
      const _langs: LangItem[] = []
      if (typeof langs === 'string') {
        langs
          .split(',')
          .forEach(l => {
            const _l = this.langs.find(tl => tl.lang === l)
            if (_l) {
              _langs.push(_l)
            }
          })
      } else {
        this.langs = langs
      }
    }
  }

  getLang (prevLang: Lang): Lang {
    if (this.langs.length === 1) {
      return this.langs[0].lang
    }
    const ru = this.langs.find(l => l.lang === 'ru')
    const en = this.langs.find(l => l.lang === 'en')
    const probability = random(0, 100)
    let _lang = ru
    // английский 30% шансов появиться и нет шанса появиться два раза подряд
    if (probability > 70 && prevLang !== 'en') {
      _lang = en
    }
    return _lang?.lang || 'ru'
  }

  init (): void {
    let isScrollTriggered = false
    let prevLang: Lang
    const list = new List()

    const getContentOnline = (): string => {
      const randPeople = random(this.typeOnline.min, this.typeOnline.max)
      const people = getNumberDecliner({
        n: randPeople,
        single: 'человек',
        multiple: 'человек',
        multiple2: 'человека'
      })

      return this.typeOnline.template
        .replace('%count%', String(randPeople))
        .replace('%people%', people)
    }

    this.onScroll = () => {
      if (window.pageYOffset >= this.typeOnline.position && !isScrollTriggered) {
        list.add({
          id: Date.now(),
          type: 'online',
          content: getContentOnline()
        })

        isScrollTriggered = true
      }
    }

    const getContentBuy = (): string => {
      prevLang = this.getLang(prevLang)

      const name = names[prevLang].name[random(names[prevLang].name.length - 1)]
      const city = names[prevLang].city[random(names[prevLang].city.length - 1)]
      return this.typeBuy.template
        .replace('%name%', name)
        .replace('%city%', city)
    }

    if (this.typeOnline.enable) {
      this.startTimer = setTimeout(() => {
        list.add({
          id: Date.now(),
          type: 'buy',
          content: getContentBuy()
        })

        this.intervalTimer = setInterval(() => {
          list.add({
            id: Date.now(),
            type: 'buy',
            content: getContentBuy()
          })
        }, this.typeBuy.interval * 1000)
      }, this.typeBuy.delay * 1000)
    }

    if (this.typeOnline.enable) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.addEventListener('scroll', this.onScroll)
    }

    const render = new Render(this.position)
    list.on('update', (payload: listItem[]) => {
      render.update(payload, (item: listItem) => {
        list.remove(item.id)
      })
    })
  }

  destroy (): void {
    if (this.startTimer) {
      clearTimeout(this.startTimer)
    }
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer)
    }
    if (this.onScroll) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.removeEventListener('scroll', this.onScroll)
    }
  }
}
