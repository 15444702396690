export type listItem = {
  id: number;
  type: string;
  status?: string;
  content?: string;
  timeoutId?: number;
}

export default class List {
  private items: listItem[] = []

  private events: { update?: (payload: listItem[]) => void } = {}

  add (item: listItem): void {
    this.items = this.items
      .filter(item => item.status !== 'hide')
      .map(item => ({ ...item, status: 'hide' }))

    const timeoutId: number = setTimeout(() => {
      this.remove(item.id)
    }, 5000)

    this.items.push({
      ...item,
      timeoutId,
      status: 'show'
    })

    this.emit('update', this.items)
  }

  remove (id: number): void {
    this.items = this.items.filter(item => {
      if (item.id === id) {
        clearTimeout(item.timeoutId)
      }
      return item.id !== id
    })
    this.emit('update', this.items)
  }

  emit (eventName: 'update', data: unknown): void {
    if (typeof this.events[eventName] === 'function') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.events[eventName](data)
    }
  }

  on (eventName: 'update', cb: (payload: listItem[]) => void): void {
    this.events[eventName] = cb
  }
}
