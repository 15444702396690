import Popups, { typeBuy, typeOnline, LangItem, Lang } from '../common/Popups/main'
import isPlainObject from 'lodash.isplainobject'

let isInit = false
const id = `anyClassInitWidgetPopup-${Math.floor(Math.random() * 10000) * 10}`

function initWidgetPopup (payload: {
  typeBuy?: typeBuy,
  typeOnline?: typeOnline,
  langs?: LangItem[] | string | Lang
} = {}) {
  if (!isPlainObject(payload)) {
    throw new Error('[acWidgetPopup] параметром может быть только объект')
  }

  if (!document.getElementById(id)) {
    const div = document.createElement('div')
    div.setAttribute('id', id)
    document.body.appendChild(div)
  }
  if (!isInit) {
    const typeBuy = {
      enable: true,
      delay: 0,
      interval: 107,
      template: '<b>%name%</b> из г. %city% только что оплатила курс "Медитация. Начало"'
    }

    const typeOnline = {
      enable: true,
      max: 8,
      min: 3,
      position: 2000,
      template: 'Сейчас еще <b>%count% %people%</b> просматривают эту страницу'
    }

    const props = {
      typeBuy,
      typeOnline,
      langs: undefined
    }

    // проверка типов входных параметров в runtime
    Object.keys(props).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (isPlainObject(payload[key])) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Object.keys(props[key]).forEach(propKey => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (payload[key][propKey]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const expectType = typeof props[key][propKey]
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const actualType = typeof payload[key][propKey]
            if (expectType === actualType) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              props[key][propKey] = payload[key][propKey]
            } else {
              console.warn(`Неверный тип \r\nОжидается (props[${key}][${propKey}]): ${expectType} \r\nПолучен (payload[${key}][${propKey}]): ${actualType}`)
            }
          }
        })
      }
    })

    const popups = new Popups(props)
    popups.init()
    isInit = true
  }
}

if (!window.acWidgetPopup) {
  window.acWidgetPopup = initWidgetPopup
}
