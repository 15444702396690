import { listItem } from '@tilda/common/List'

const createRootElement = (position: string): HTMLElement => {
  const rootElement = document.createElement('div')
  rootElement.classList.add('popups')
  rootElement.classList.add(...position.split(' '))
  return rootElement
}

const icon = `
<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.6 1.52798L10.472 0.399982L6.00002 4.87198L1.52802 0.399982L0.400024 1.52798L4.87202 5.99998L0.400024 10.472L1.52802 11.6L6.00002 7.12798L10.472 11.6L11.6 10.472L7.12802 5.99998L11.6 1.52798Z" fill="#3C2F4B"/>
</svg>`

const createChildElement = (): HTMLElement => {
  const rootElement = document.createElement('div')
  rootElement.classList.add('popup')

  const wrapperElement = document.createElement('div')
  wrapperElement.classList.add('popup_wrapper')

  const contentElement = document.createElement('div')
  contentElement.classList.add('popup_content')

  const buttonElement = document.createElement('button')
  buttonElement.classList.add('popup_button', 'popup_button_close')
  buttonElement.innerHTML = icon

  wrapperElement.appendChild(contentElement)
  wrapperElement.appendChild(buttonElement)

  rootElement.appendChild(wrapperElement)

  return rootElement
}

export default class Render {
  private readonly rootElement: HTMLElement
  private childElement: HTMLElement

  constructor (position: string) {
    this.rootElement = createRootElement(position)
    this.childElement = createChildElement()

    document.querySelector('body')!.appendChild(this.rootElement)

    const style = document.createElement('style')
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const css = require('./style.css')
    style.innerHTML = css.toString()
    document.querySelector('body')!.appendChild(style)
  }

  update (payload: listItem[], click: (listItem: listItem) => void): void {
    this.rootElement.innerHTML = ''
    const elements: HTMLElement[] = []
    payload.forEach(item => {
      const el = this.childElement.cloneNode(true) as HTMLElement
      if (item.status) {
        el.classList.add(item.status)
      }
      el.querySelector('.popup_content')!.innerHTML = String(item.content)
      el.addEventListener('click', () => {
        click(item)
      })
      elements.push(el)
    })
    this.rootElement.append(...elements)
  }
}
